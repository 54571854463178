<template>
  <div class="card pt-5 pb-5">
    <div class="overflow-hidden">
      <div class="card-header" />
      <div class="card-body thanks inner-scroll">
        <h1>{{ $t('thanks_interest_title') }}</h1>
        <p>{{ $t('thanks_interest_desc_1') }}</p>
        <br />
        <b-link to="/overview" class="btn btn-lg btn-primary">{{ $t('Back to overview') }}</b-link>
      </div>
    </div>
  </div>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import ToastificationContent from '@/components/ToastificationContent.vue'

export default {
  name: 'ThanksInterest',
  components: {
    BLink,
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Text copied',
          icon: 'BellIcon',
        },
      })
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Failed to copy texts!',
          icon: 'BellIcon',
        },
      })
    },
  },
}
</script>

<style scoped></style>
